import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout/layoutWithContainer.js";
import SEO from "../components/seo";
import Technologies from "../components/landing-page/technologies/technologies";
import { Link } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Technologies we are experienced with" mdxType="SEO" />
    <div className="showcaseSection">
    <div className="prose">
        <h1>Knowledge</h1>
        <p>Our consultants have extensive experience with the following:</p>
    </div>
    <hr />
        <Technologies showAll={true} mdxType="Technologies" />
    <hr />
    <p>Would you like to work with us?</p>
    <Link to="contact-us" className="button" mdxType="Link">
        Get in touch
    </Link>
    <br /><br />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      